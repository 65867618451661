.loading {
  animation: catavento infinite 20s linear;
}

@keyframes catavento {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
